<script>
import $ from "jquery";
import lottie from "lottie-web";
import animationData from "@/assets/json/proba.json";
export default {
  name: "PageGame",
  data() {
    return {
      user: {
        email: "",
        balance: 10000,
        result: [
          {
            label: "plus",
            value: 497025,
          },
          {
            label: "minus",
            value: 297030,
          },
          {
            label: "total",
            value: 794055,
          },
        ],
        name: "@test_test",
        password: "",
        avatar_file: null,
      },
      users: [
        {
          id: 1,
          src: "../images/avatar.png",
          nickname: "test_test",
        },
        {
          id: 2,
          src: "../images/avatar2.png",
          nickname: "charley",
        },
        {
          id: 3,
          src: "../images/avatar2.png",
          nickname: "henessy",
        },
        {
          id: 4,
          src: "../images/avatar2.png",
          nickname: "teqila",
        },
        {
          id: 5,
          src: "../images/avatar2.png",
          nickname: "weskey",
        },
        {
          id: 6,
          src: "../images/avatar2.png",
          nickname: "cocacola",
        },
        {
          id: 7,
          src: "../images/avatar2.png",
          nickname: "beyonce",
        },
        {
          id: 8,
          src: "../images/avatar2.png",
          nickname: "sprite",
        },
        {
          id: 9,
          src: "../images/avatar2.png",
          nickname: "vodka",
        },
        {
          id: 10,
          src: "../images/avatar2.png",
          nickname: "mineralka",
        },
      ],
      winner: {},
      totalPrise: 0,
      totalTime: 10 * 1000, // 20 секунд в миллисекундах
      intervalId: null, // Для хранения идентификатора интервала
      remainingTime: 10 * 1000, // Оставшееся время
      winnerFlag: false,
      animation: null,
      selectedUsers: [],
    };
  },
  methods: {
    getRandomUsers(users) {
      // Всегда добавляем первого пользователя в выбранные
      this.selectedUsers = [users[0]];

      // Определяем случайное количество пользователей для выбора (от 2 до 10)
      const numberOfUsersToSelect =
        Math.floor(Math.random() * (10 - 2 + 1)) + 2;

      // Копируем массив за исключением первого элемента
      const remainingUsers = users.slice(1);

      // Перемешиваем оставшихся пользователей
      for (let i = remainingUsers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [remainingUsers[i], remainingUsers[j]] = [
          remainingUsers[j],
          remainingUsers[i],
        ];
      }

      // Выбираем случайные элементы из оставшихся
      const randomSelection = remainingUsers.slice(
        0,
        numberOfUsersToSelect - 1
      );

      // Добавляем их к уже выбранному первому пользователю
      this.selectedUsers.push(...randomSelection);

    },
    startTimer() {
      this.remainingTime = this.totalTime;

      this.intervalId = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 10; // Уменьшаем оставшееся время на 10 миллисекунд
        } else {
          clearInterval(this.intervalId); // Останавливаем таймер, когда время заканчивается
          this.remainingTime = 0; // Устанавливаем оставшееся время в 0
          this.$emit("goGame");
          $(".game-wrapper").addClass("go");
          $(".ready").hide();
          $(".go").show(300);

          setTimeout(() => {
            this.startAnimation();
          }, 500);
        }
      }, 10); // Интервал обновления таймера — 10 миллисекунд
    },
    startTimer2() {
      const randomIndex = Math.floor(Math.random() * this.selectedUsers.length);
      const winner = this.selectedUsers[randomIndex];
      let comission = (3 * this.selectedUsers.length) * 0.2;
      this.totalPrise = 3 * this.selectedUsers.length - comission;
      this.$emit("goGameFinal");
      $(".game-wrapper").removeClass("go");
      $(".ready").hide();
      $(".go").hide(0);
      let video = document.querySelector(".video");
      video.pause();
      video.currentTime = 0;
      this.winner = winner;
      this.winnerFlag = true;
      if (winner.id === 1) {
        this.$emit("deposit", this.totalPrise);
        console.log(this.totalPrise);
      } else {
        this.$emit("deposit", -3);
        console.log(-3);
      }
      $(".winner").show(300);
    },
    startAnimation() {
      // document.querySelector(".video").play();
      this.$emit("video");
      this.onAnimationComplete();
    },
    onAnimationComplete() {
      // Запускаем CSS-анимацию
      this.cssAnimationTriggered();
    },
    cssAnimationTriggered() {
      let container = document.querySelector(".usersGo");
      let spanGo = document.querySelector(".game__bar .title");
      let height = container.offsetHeight;
      let time = height;
      console.log(height, time);
      let height2 = 0;
      this.cssAnimationInterval = setInterval(() => {
        if (time <= 0) {
          clearInterval(this.cssAnimationInterval);
          spanGo.style.transform = `translateY(0px)`;
          this.startTimer2();
        } else {
          container.style.transform = `translateY(-${height2}px)`;
          spanGo.style.transform = `translateY(-${height2}px)`;
          height2 += 2;
          time -= 2; // Уменьшаем время на 0.01 для каждой итерации
        }
      }, 10);
    },
    playAgain() {
      this.$emit("playAgain");
      this.getRandomUsers(this.users);
      let container = document.querySelector(".usersGo");

      container.style.transform = `translateY(calc(100% - 40px))`;

      // Запуск таймера при загрузке компонента
      this.winnerFlag = false;
      $(".go").hide();

      $(".scroodge").hide();
      $(".winner").hide();
      $(".ready").show();
      $(".game-wrapper").removeClass("go");
      this.startTimer();
      this.animation = lottie.loadAnimation({
        container: this.$refs.lottieContainer,

        loop: false, // Отключаем зацикливание
        autoplay: false, // Не запускаем автоматически
        animationData: animationData,
      });
    },
    goDashboard() {
      this.$emit("goDashboard");
    },
  },
  computed: {
    seconds() {
      const seconds = Math.floor(this.remainingTime / 1000);
      return seconds >= 0 ? String(seconds).padStart(2, "0") : "00";
    },
    milliseconds() {
      const milliseconds = Math.floor((this.remainingTime % 1000) / 10);
      return milliseconds >= 0 ? String(milliseconds).padStart(2, "0") : "00";
    },
  },
  mounted() {
    this.startTimer(); // Запуск таймера при загрузке компонента
    $(".go").hide();

    $(".scroodge").hide();
    $(".winner").hide();
    $(".ready").show();
    this.animation = lottie.loadAnimation({
      container: this.$refs.lottieContainer,

      loop: false, // Отключаем зацикливание
      autoplay: false, // Не запускаем автоматически
      animationData: animationData,
    });
    console.log(this.animation);
    this.getRandomUsers(this.users);
  },
  beforeUnmount() {
    if (this.animation) {
      this.animation.destroy();
    }
  },
};
</script>

<template>
  <div class="container wrapper login-wrapper game-wrapper">
    <div class="head">
      <div class="loading">
        <div class="avatar-wrp">
          <svg
            class="loading__svg"
            xmlns="http://www.w3.org/2000/svg"
            width="140"
            height="48"
            viewBox="0 0 140 48"
            fill="none"
          >
            <path
              class="loading-path"
              d="M24 1H116C128.703 1 139 11.2975 139 24C139 36.7025 128.703 47 116 47H70H24C11.2974 47 1 36.7025 1 24C1 11.2975 11.2975 1 24 1Z"
              stroke="#3CF"
              stroke-width="2"
            />
          </svg>
          <div class="avatar">
            <img src="../assets/img/avatar.svg" alt="" />
          </div>
        </div>
        <div class="name">{{ user.name }}</div>
      </div>
    </div>
    <div :class="`game__bar ${winnerFlag ? 'winners' : ''}`">
      <div class="title">
        <span class="ready">Ready?</span><span class="go">Go</span
        ><span class="winner">The winner!</span>
      </div>
      <div class="ready__stage ready">
        <div class="users">
          <div v-for="user in selectedUsers" :key="user.id" class="user">
           
            <img :src="user.src" alt="" />
          </div>
        </div>
        <div class="timer">
          <span class="second">{{ seconds }}</span
          >:<span class="miliSecond">{{ milliseconds }}</span>
        </div>
      </div>
      <div class="go__stage go">
        <div class="usersGo">
          <div v-for="user in selectedUsers" :key="user.id" class="userGo">
            <svg
              v-if="user.id !== 1"
              class="go__svg"
              width="25"
              height="152"
              viewBox="0 0 25 152"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2031_275)">
                <g style="mix-blend-mode: overlay">
                  <path d="M1 1V666" stroke="white" stroke-width="2" />
                  <path d="M0 1H25" stroke="white" stroke-width="2" />
                  <path d="M0 31H12" stroke="white" stroke-width="2" />
                  <path d="M0 61H12" stroke="white" stroke-width="2" />
                  <path d="M0 91H12" stroke="white" stroke-width="2" />
                  <path d="M0 121H12" stroke="white" stroke-width="2" />
                  <path d="M0 151H25" stroke="white" stroke-width="2" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2031_275">
                  <rect width="25" height="152" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <img :src="user.src" alt="" />

          </div>
        </div>
      </div>
      <div class="result winner">
        <div class="win">
          <div class="avatarWin">
            <img :src="winner.src" alt="" />
          </div>
          <div class="label">{{ winner.nickname }}</div>
          <div class="prise">
            <span>+ ${{ totalPrise }}</span>
          </div>
        </div>
      </div>
      <div class="btns winner">
        <a @click="playAgain" class="btn registerBtn">Play again!</a>
        <a @click="goDashboard" class="btn loginBtn dark">Go to menu</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 140px;
}

.avatar-wrp {
  display: flex;
  align-items: center;
  width: 140px;
  height: 48px;
  border-radius: 1000px;
  background: #32384d;
  padding: 2px;
  position: relative;
  overflow: hidden;
}

.avatar {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  overflow: hidden;
}
.loading__svg {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loading-path {
  stroke-dasharray: 450; /* Общая длина штриха (на основе длины пути) */
  stroke-dashoffset: 350; /* Начальная позиция штриха (невидимая) */
  animation: draw 20s linear forwards; /* Анимация на 20 секунд */
}

@keyframes draw {
  to {
    stroke-dashoffset: 0; /* Конечная позиция штриха (путь полностью виден) */
  }
}
.avatar {
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name {
  color: var(---White, #fff);
  text-align: center;
  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%; /* 13.8px */
}
.game__bar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
  height: 264px;
  position: relative;
  z-index: 1;
}
.game__bar.winners {
  margin-bottom: auto;
  height: 100%;
  justify-content: space-between;
}
.users {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  max-width: 284px;
  width: 100%;
}
.title {
  width: 100%;
  text-align: center;
}
.user {
  width: 52px;
  height: 52px;
  border-radius: 1000px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 2px solid #0095ff;
}
.user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.timer {
  color: var(---White, #fff);
  text-align: center;
  font-family: Jost;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 23px */
}
.ready__stage {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-direction: column;
}
.usersGo {
  display: flex;
  flex-direction: column;
  gap: 122px;
  transform: translateY(calc(100% - 40px));
}
span.go {
  /* display: block;
  transform: translateY(0px); */
}
.userGo {
  width: 28px;
  height: 28px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.userGo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.go__stage {
  width: 100%;
  padding-left: 20px;
}
.go__svg {
  position: absolute;
  bottom: 12px;
  left: -35px;
}

.game-wrapper {
  overflow: hidden;
}
.win {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
}
.label {
  color: var(---White, #fff);
  text-align: center;
  font-family: Jost;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
}
.avatarWin {
  width: 106.701px;
  height: 106.701px;
  flex-shrink: 0;
  border: 4px solid #0095ff;
  padding: 6px;
  border-radius: 1000px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatarWin img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.prise {
  border-radius: 1000px;
  background: var(---01, rgba(0, 0, 0, 0.8));
  backdrop-filter: blur(5px);

  margin-top: 8px;
  padding: 10px 18px;
}
.prise span {
  background: var(
    --unnamed,
    radial-gradient(163.66% 123.55% at 1.85% 100%, #fff 0%, #fff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 22px #ff3370;
  font-family: Jost;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 25.3px */
}
.result {
  margin-bottom: auto;
}
</style>