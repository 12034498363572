import router from './router/router'
import '../src/assets/css/style.css';
import { createApp } from 'vue'
import App from './App.vue'

import store from "@/store";

createApp(App).use(store).use(router).mount('#app')

document.addEventListener('touchend', function(event) {
    // Если клик произошел не на инпуте
    if (!event.target.closest('input, textarea')) {
        // Скрываем клавиатуру, убирая фокус с инпута
        document.activeElement.blur();
    }
});