<script>
import $ from "jquery";
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: "PageLogin",
  data() {
    return {
      email: "",
      password: "",
      error: null,
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  methods: {
    goLogin() {
        let loginInputs = document.querySelectorAll(".loginInputs input");
      let flag = false;
      loginInputs.forEach((i) => {
        i.addEventListener("input", function () {
          for (let input of loginInputs) {
            if (input.value !== "") {
              flag = true;
            } else {
              flag = false;
            }
          }

          if (flag) {
            console.log(flag);
            $(".loginBtnForm").removeClass("default");
          }
        });
      });
      $(".loginBtnForm").click(function (e) {
        e.preventDefault();
        if (this.classList.contains("default")) {
          return;
        }
      });
    },
    login() {
      if (
        document.querySelector(".loginBtnForm").classList.contains("default")
      ) {
        return;
      }
      let email = document.querySelector(".emailLogin").value;
      let password = document.querySelector(".passwordLogin").value;
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      let params = {
        username: email,
        password: password,
      };
      axios
        .post(this.apiUrl + "api-user/login" + authGet, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log("userLogin", response);
          if (response.data.status) {
            this.$store.commit("setUserData", response.data.user);
            let jsonUser = JSON.stringify(response.data.user);
            Cookies.set("user", jsonUser, { expires: 7 });
            this.$emit("loginSuccess");
          } else {
            this.error = response.data.error;
          }
        });
    },
    goToRegister() {
      this.$emit("goToRegister");
    },
    goBack() {
      this.$emit("goBack");
    }
  },
  mounted() {
    this.goLogin();
  },
};
</script>

<template>
  <div class="container wrapper login-wrapper">
    <div @click="goBack" class="back">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.20412 11.2812C2.47927 10.5673 2.47927 9.40991 3.20412 8.69604L11.4902 0.535404C12.2151 -0.178466 13.3903 -0.178466 14.1151 0.535405C14.84 1.24928 14.84 2.40669 14.1151 3.12056L7.14148 9.98862L14.1151 16.8567C14.84 17.5705 14.84 18.728 14.1151 19.4418C13.3903 20.1557 12.2151 20.1557 11.4902 19.4418L3.20412 11.2812Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="log__head">
      <h2 class="head-h2">Log in</h2>
      <div class="description">
        Enter your details <br />
        to create an account
      </div>
    </div>
    <form action="">
      <div class="inputs loginInputs">
        <input
          type="text"
          class="input emailLogin"
          placeholder="Email"
        />
        <input
          type="password"
          
          class="input passwordLogin"
          placeholder="Password"
        />
      </div>
      <div @click="goToRegister" class="subform">I don't have an account</div>
      <input
        readonly
        onfocus="this.removeAttribute('readonly')"
        type="submit"
        class="submit loginBtnForm default"
        value="Confirm"
        @click="login"
      />
    </form>
  </div>
</template>



