import { createStore} from "vuex";

export default createStore({
    state:{
        user: {
            user_id: false,
            username: 'guest', //--не залогинен
            auth_key: 'YdZ2jtC4aPcjUtN6_g_HzkkzuXKf0XLv',
            email:'',
            role:{
                item_name:'guest'
            },
            name:'',
            isLogged: false,
        },
        isMobile: false,
        apiUrl: 'https://api.test-onion.ru/web/index.php?r=',
        apiDomain: 'https://api.test-onion.ru',
        flash_messsage: {
            title: '',
            massage: '',
            type: ''
        },
    },
    mutations:{
        setUser(state, user){
            state.user = user
        },
        setUserData(state, user){

            if(user.role.user_id)     { state.user.user_id = user.role.user_id}
            if(user.username)         { state.user.username = user.username}
            if(user.auth_key)         { state.user.auth_key = user.auth_key}
            if(user.tg)               { state.user.tg = user.tg}
            if(user.email)            { state.user.email = user.email}
            if(user.role)             { state.user.role = user.role}
            if(user.profile)          { state.user.profile = user.profile}
        },
        setLogged(state, logged){
            state.user.isLogged = logged
        },
        setIsMobile(state, isMobile){
            state.isMobile = isMobile
        },
        setFlashMessage(state, flashMessage) {
            state.flash_messsage = flashMessage
        }
    },
    getters:{
        getUser:state => {

            return state.user
        },
        getIsMobile:state => {

            return state.isMobile
        },
        getApiUrl:state => {

            return state.apiUrl
        },
        getApiDomain:state => {

            return state.apiDomain
        },
        getFlashMessage:state => {
            return state.flash_messsage
        }
    },
})