<script>
// import axios from "axios";
// import $ from "jquery";
export default {
  props: {
    deposit: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      user: {
        email: "",
        balance: 10000.30,
        result: [
          {
            label: "plus",
            value: 4970.25,
          },
          {
            label: "minus",
            value: 2970.30,
          },
          {
            label: "total",
            value: 7940.55,
          },
        ],
        name: "@test_test",
        password: "",
        avatar_file: null,
      },
    };
  },

  computed: {
    formattedBalance() {
      return this.splitNumber(this.user.balance);
    },
    formattedPlus() {
      const plusItem = this.user.result.find((item) => item.label === "plus");
      return plusItem ? this.splitNumber(plusItem.value) : [];
    },
    formattedMinus() {
      const minusItem = this.user.result.find((item) => item.label === "minus");
      return minusItem ? this.splitNumber(minusItem.value) : [];
    },
  },

  methods: {
    splitNumber(num) {
  // Округляем число до 2-х знаков после запятой
  const roundedNum = num.toFixed(2);
  
  // Преобразуем число в строку
  const str = roundedNum.toString();

  // Разделяем строку на целую часть и десятичную часть
  const [integerPart, decimalPart] = str.split('.');

  const result = [];

  // Добавляем десятичную часть (центы)
  result.push(decimalPart);

  // Разделяем целую часть на части по три символа справа налево
  let remainingStr = integerPart;

  while (remainingStr.length > 0) {
    const part = remainingStr.slice(-3); // Берем последние три символа
    result.unshift(part); // Добавляем часть в начало массива
    remainingStr = remainingStr.slice(0, -3); // Удаляем обработанную часть
  }

  return result;
},
    playToGame() {
      this.$emit("playToGame");
    },
  },
  mounted() {
    this.user.balance += this.deposit;
  }
};
</script>

<template>
  <div class="container wrapper login-wrapper">
    <div class="head">
      <a class="home">
        <img src="../assets/img/Button.svg" alt="" />
      </a>
      <div class="avatar">
        <img src="../assets/img/avatar.svg" alt="" />
        <div class="name">{{ user.name }}</div>
      </div>
      <a class="setting">
        <img src="../assets/img/setting.svg" alt="" />
      </a>
    </div>
    <div class="balance">
      <div class="balance-wrapper">
        <div class="label">Total balance</div>
        <div class="balance-text">
          $ {{ formattedBalance.join(",").replace(/,(?=\d{2}$)/, ".") }}
        </div>
      </div>
      <div class="result-mounth">
        <div class="label">This month</div>
        <div class="numbers">
          <div class="number">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
            >
              <path
                d="M4.56497 3.5C5.86943 1.16667 6.52165 0 7.5 0C8.47834 0 9.13057 1.16667 10.435 3.5L13.3701 8.75C14.6745 11.0833 15.3268 12.25 14.8376 13.125C14.3484 14 13.044 14 10.435 14H4.56496C1.95604 14 0.651585 14 0.162413 13.125C-0.326759 12.25 0.32547 11.0833 1.62993 8.75L4.56497 3.5Z"
                fill="#00C0A0"
              />
            </svg>
            <div class="balance-mounth">
              $
              <span
                v-for="(part, index) in formattedPlus.slice(0, -1)"
                :key="index"
              >
                <span v-if="index !== formattedPlus.length - 2"
                  >{{ part }},</span
                >
              </span>
              <span>{{ formattedPlus[formattedPlus.length - 2] }}.</span>
              <span class="cents">{{
                formattedPlus[formattedPlus.length - 1]
              }}</span>
            </div>
          </div>
          <div class="number">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
            >
              <path
                d="M4.56497 10.5C5.86943 12.8333 6.52166 14 7.5 14C8.47834 14 9.13057 12.8333 10.435 10.5L13.3701 5.25C14.6745 2.91667 15.3268 1.75 14.8376 0.874999C14.3484 -9.53674e-07 13.044 0 10.435 0H4.56496C1.95605 0 0.651586 -9.53674e-07 0.162413 0.874999C-0.326759 1.75 0.32547 2.91667 1.62993 5.25L4.56497 10.5Z"
                fill="#FF4D4E"
              />
            </svg>
            <div class="balance-mounth">
              $
              <span
                v-for="(part, index) in formattedMinus.slice(0, -1)"
                :key="index"
              >
                <span v-if="index !== formattedMinus.length - 2"
                  >{{ part }},</span
                >
              </span>
              <span>{{ formattedMinus[formattedMinus.length - 2] }}.</span>
              <span class="cents">{{
                formattedMinus[formattedMinus.length - 1]
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btnsProfile">
        <a  class="btnDeposit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.63581 2.47071L1.92651 2.4708C1.38232 2.47081 0.941165 2.02967 0.941158 1.48548C0.94115 0.941293 1.38229 0.500137 1.92648 0.500129L11.0146 0.5C11.276 0.499996 11.5266 0.603807 11.7114 0.788594C11.8962 0.973381 12 1.22401 12 1.48534V10.4442C12 10.9884 11.5588 11.4296 11.0147 11.4296C10.4705 11.4296 10.0293 10.9884 10.0293 10.4442V3.86415L1.68208 12.2114C1.29728 12.5962 0.673397 12.5962 0.288599 12.2114C-0.0961995 11.8266 -0.0961995 11.2027 0.288599 10.8179L8.63581 2.47071Z"
              fill="white"
            />
          </svg>
          Deposit
        </a>
        <a  class="btnWithdraw">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.788598 0.788599C1.1734 0.4038 1.79728 0.4038 2.18208 0.788599L10.5293 9.13581L10.5292 2.42651C10.5292 1.88232 10.9703 1.44116 11.5145 1.44115C12.0587 1.44115 12.4999 1.88229 12.4999 2.42648L12.5 11.5146C12.5 11.776 12.3962 12.0266 12.2114 12.2114C12.0266 12.3962 11.776 12.5 11.5147 12.5H2.55575C2.01156 12.5 1.57041 12.0588 1.57041 11.5147C1.57041 10.9705 2.01156 10.5293 2.55575 10.5293H9.13585L0.788599 2.18208C0.403801 1.79728 0.4038 1.1734 0.788598 0.788599Z"
              fill="white"
            />
          </svg>
          Withdraw
        </a>
      </div>
      <a @click="playToGame" class="btnPlay"> Play for $3 </a>
    </div>
  </div>
</template>


<style scoped>
.head {
  display: flex;
  justify-content: space-between;

  width: 100%;
}
.avatar {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.name {
  color: #fff;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 18.4px */
}
.balance {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.balance-wrapper {
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}
.label {
  color: var(--Theme-Blue-500, #7a8299);
  text-align: center;
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 19.8px */
}
.balance-text {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 18px rgba(255, 255, 255, 0.4),
    0px 0px 60px rgba(255, 255, 255, 0.32);
  font-family: Jost;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 41.8px */
}
.wrapper {
  justify-content: flex-start;
}
.balance-mounth {
  color: #fff;
  text-align: center;
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 19.8px */
  display: flex;
}
.cents {
  color: var(--Theme-Blue-600, #656d85);
  text-align: center;
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 19.8px */
}
.number {
  display: flex;
  align-items: center;
  gap: 6px;
}
.numbers {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 6px;
}
.btnsProfile {
  display: flex;
  justify-content: center;
}
.btnDeposit {
  background: var(--Color-Mart-B3, #0095ff);
  color: #fff;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 33px;
  gap: 10px;
  width: 140px;
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
}
.btnWithdraw {
  background: var(--Color-Mart-V3, #8972ff);
  color: #fff;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 33px;
  gap: 10px;
  width: 140px;
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
}
.btnPlay {
  border-radius: 80px;
  background: var(
    --unnamed,
    radial-gradient(
      86.37% 93.87% at 82.42% 65.11%,
      #ff7d61 19%,
      rgba(255, 96, 170, 0) 100%
    ),
    radial-gradient(
      103.99% 103.99% at 87.5% 3.01%,
      #ff0ac9 0%,
      rgba(130, 133, 255, 0) 100%
    ),
    radial-gradient(
      79.15% 79.15% at -9.03% 47.27%,
      #fff 0%,
      rgba(126, 131, 255, 0) 100%
    ),
    radial-gradient(163.66% 123.55% at 1.85% 100%, #fff 0%, #fff 100%)
  );
  box-shadow: 0px 4px 60px 0px rgba(255, 95, 114, 0.5);
  color: var(---White, #fff);
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 33px;
}
</style>
