<script>
import $ from "jquery";
import { useCookies } from "vue3-cookies";
import ProfilePage from "./components/PageProfile.vue";
import RegisterationPage from "./components/RegisterationPage.vue";
import PageGame from "./components/PageGame.vue";
import PageLogin from "./components/PageLogin.vue";

export default {
  name: "App",
  components: {
    RegisterationPage,
    ProfilePage,
    PageGame,
    PageLogin,
  },
  data() {
    return {
      showRegister: false,
      showProfile: false,
      showGame: false,
      showGoGame: false,
      showGoGame2: false,
      showLogin: false,
      userFlag: false,
      depositclick: 0,
    };
  },
  computed: {
    mainClass() {
      if (this.showGame && !this.showGoGame && !this.showGoGame2) {
        return "game";
      } else if (this.showProfile) {
        return "profile";
      } else if (this.showLogin) {
        return "register";
      } else if (this.showGoGame && !this.showGoGame2) {
        return "goGame game";
      } else if (this.showGoGame2) {
        return "goGame game2";
      } else if (this.showRegister) {
        return "register";
      } else {
        return "main"; // Default background
      }
    },
  },
  methods: {
    playAgain() {
      this.showGame = true;
      this.showProfile = false;
      this.showRegister = false;
      this.showGoGame = false;
      this.showGoGame2 = false;
    },
    deposit(balance) {
      this.depositclick += balance;
    },
    checkCookie() {
      let user = this.cookies.isKey("user") ? this.cookies.get("user") : false;
      console.log("UserAPP", user);
      if (user) {
        this.$store.commit("setUserData", user);
        this.userFlag = true;
      }
    },
    playToGame() {
      this.showGame = !this.showGame;
      this.showProfile = false;
      this.showRegister = false;
      $(".main-wrapper").hide();
    },
    goGame() {
      this.showGoGame = !this.showGoGame;
    },
    goGameFinal() {
      this.showGoGame2 = !this.showGoGame2;
    },
    goRegister() {
      this.showRegister = !this.showRegister;
      this.showProfile = false;
      this.showLogin = false;
      this.showGame = false;
      this.showGoGame = false;
      this.showGoGame2 = false;
      $(".main-wrapper").toggle(!this.showRegister);
    },
    goLogin() {
      if (this.userFlag) {
        this.goProfile();
        return;
      }
      this.showLogin = !this.showLogin;
      this.showRegister = false;
      this.showProfile = false;
      this.showGame = false;
      this.showGoGame = false;
      this.showGoGame2 = false;
      $(".main-wrapper").toggle(!this.showLogin);
    },
    goProfile() {
      this.showProfile = !this.showProfile;
      this.showLogin = false;
      this.showRegister = false;
      this.showGame = false;
      this.showGoGame = false;
      this.showGoGame2 = false;
      $(".main-wrapper").toggle(!this.showProfile);
      $(".main-img").toggle(!this.showProfile);
    },
    videoPlay() {
      $(".video").get(0).play();
    },
    goPage(flag = false) {
      this.showProfile = false;
      this.showLogin = false;
      this.showRegister = false;
      this.showGame = false;
      this.showGoGame = false;
      this.showGoGame2 = false;
      if (flag) {
        $(".main-img").show();

        $(".main-wrapper").show();
      }

      $(".registerBtn").click(function () {
        $(".main-img").hide();

        $(".main-wrapper").hide();
      });
      $(".loginBtn").click(function () {
        $(".main-img").hide();

        $(".main-wrapper").hide();
      });
      const video = document.querySelector(".video");

      // Слушаем событие loadeddata, которое срабатывает, когда первый кадр загружен
      video.addEventListener("loadeddata", () => {
        video.pause(); // Ставим видео на паузу на первом кадре
        video.classList.add("ready"); // Добавляем класс, чтобы показать видео
      });

      // Загружаем видео
      video.load();
    },
  },
  mounted() {
    this.goPage();
    this.checkCookie();
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
};
</script>
<template>
  <section :class="['main', mainClass]">
    <video
      preload="auto"
      :class="['video abs', mainClass]"
      
      src="@/assets/img/video3.mp4"
      muted
      playsinline
    ></video>
    <img class="abs main-img" src="../assets/img/scrudj1.png" alt="" />
    <div class="container wrapper main-wrapper">
      <h1 class="title">Sanchezz</h1>
      <div class="footer">
        <div class="btns">
          <a @click="goRegister" class="btn registerBtn">Register</a>
          <a @click="goLogin" class="btn loginBtn dark">Log in</a>
        </div>
        <!-- <a @click="goProfile" class="btn rose"
          >Watch the game
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14.7252 9.5614C15.7583 8.77105 15.7583 7.22896 14.7252 6.43861C11.6034 4.05019 8.11733 2.16761 4.39761 0.861362L3.71806 0.622722C2.41801 0.166182 1.04481 1.03589 0.868809 2.3628C0.377064 6.07026 0.377064 9.92975 0.868809 13.6372C1.04481 14.9641 2.41801 15.8339 3.71806 15.3773L4.39761 15.1387C8.11733 13.8324 11.6034 11.9499 14.7252 9.5614Z"
              fill="white"
            />
          </svg>
        </a> -->
      </div>
    </div>

    <page-login
      v-if="showLogin"
      @loginSuccess="goProfile"
      @goToRegister="goRegister"
      @goBack="goPage(true)"
    />
    <RegisterationPage
      v-if="showRegister"
      @registerSuccess="goLogin"
      @goToLogin="goLogin"
      @goBack="goPage(true)"
    />
    <ProfilePage
      v-if="showProfile"
      @playToGame="playToGame"
      :deposit="depositclick"
    />
    <PageGame
      v-if="showGame"
      @video="videoPlay"
      @goProfile="goProfile"
      @goGame="goGame"
      @goGameFinal="goGameFinal"
      @playAgain="playAgain"
      @goDashboard="goProfile"
      @deposit="deposit"
    />
  </section>
</template>

<style>
section.main {
  overflow: hidden;
}
</style>
